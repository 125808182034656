import React, {useState} from 'react';
import {
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  HStack, Icon,
  IconButton,
  Image,
  Input,
  Text, Tooltip,
  VStack
} from "@chakra-ui/react";
import {DamageReportStatus, IDamageReport, IDamageReportEvent} from "../../models/damageReports";
import {CheckIcon, CloseIcon} from "@chakra-ui/icons";
import {getStatusColor} from "../../utils";
import {MdArchive, MdDelete, MdHistory} from "react-icons/md";

interface DamageReportCardProps {
  damageReport: IDamageReport;
  disabled: boolean;
  updateDamageReport: (stockId: number, update: Partial<IDamageReport>) => void;
  deleteDamageReport: (stockId: number) => void;
  showEvents: (events: IDamageReportEvent[]) => void;
}

const DamageReportCard: React.FC<DamageReportCardProps> = (props: DamageReportCardProps) => {
  const {
    damageReport,
    disabled,
    updateDamageReport,
    deleteDamageReport,
    showEvents
  } = props;
  const [cost, setCost] = useState<number>(damageReport.cost)

  return (
      <Card marginY='18px'>
        <CardBody>
          <Flex gap={10}>
            <VStack width="20%" spacing={4}>
              <Image
                  boxSize='150px'
                  objectFit='contain'
                  src={damageReport.stock.imageUrl}
                  alt={damageReport.stock.name}/>
              <Heading size='sm'>{damageReport.stock.name}</Heading>
              <Text color='green'>{damageReport.stock.qty} in Stock</Text>
            </VStack>
            <VStack width='80%' alignItems='flex-start' spacing={4}>
              <HStack width='100%' justifyContent='space-between' spacing={4}>
                <HStack spacing={2}>
                  <Heading size='sm'>Damage report</Heading>
                  <Text fontWeight={"bold"} color={getStatusColor(damageReport.status)}>{damageReport.status}</Text>
                </HStack>

                <HStack spacing={2}>
                  <Tooltip label='History'>
                    <IconButton
                        variant='outline'
                        aria-label='History'
                        onClick={() => showEvents(damageReport.events)}
                        icon={<Icon fontSize='20px' as={MdHistory}/>}
                    />
                  </Tooltip>
                  {
                      damageReport.status !== DamageReportStatus.ARCHIVED &&
                      <Tooltip label='Archive'>
                          <IconButton
                              variant='outline'
                              aria-label='Archive'
                              colorScheme='yellow'
                              icon={<Icon fontSize='20px' as={MdArchive}/>}
                              onClick={() => updateDamageReport(damageReport.stock.id, {status: DamageReportStatus.ARCHIVED})}
                          />
                      </Tooltip>
                  }
                  {
                      !disabled &&
                      <Tooltip label='Delete'>
                          <IconButton
                              variant='outline'
                              aria-label='Delete'
                              colorScheme={'red'}
                              icon={<Icon fontSize='20px' as={MdDelete}/>}
                              onClick={() => deleteDamageReport(damageReport.stock.id)}
                          />
                      </Tooltip>
                  }

                </HStack>

              </HStack>
              <Text>{damageReport.description}</Text>
              <HStack width='100%'>
                <Input
                    width={'40%'}
                    type='number'
                    disabled={disabled}
                    placeholder='Cost to repair'
                    value={cost}
                    onChange={(event) => setCost(+event.target.value)}
                />
                {
                    !disabled &&
                    <Button
                        onClick={() => updateDamageReport(damageReport.stock.id, {cost})}
                    >Submit</Button>}
                <HStack alignSelf='flex-end'>
                  <Tooltip label='Accept' bg='green' color='white'>
                    <IconButton
                        colorScheme='green'
                        aria-label='Accept'
                        onClick={() => updateDamageReport(damageReport.stock.id, {status: DamageReportStatus.APPROVED})}
                        icon={<CheckIcon/>}
                    />
                  </Tooltip>

                  <Tooltip label='Reject' bg='red' color='white'>
                    <IconButton
                        colorScheme='red'
                        aria-label='Reject'
                        onClick={() => updateDamageReport(damageReport.stock.id, {status: DamageReportStatus.CANCELLED})}
                        icon={<CloseIcon/>}
                    />
                  </Tooltip>

                </HStack>
              </HStack>
            </VStack>
          </Flex>
        </CardBody>

      </Card>
  );
};

export default DamageReportCard;