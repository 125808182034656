import React from "react";
import { CategoryListPopover } from "../pages/components/CategoryListPopover";
import useCategory from "../../hooks/useCategory";

export const CategoryListPopoverView: React.FC = () => {
  const { categories,loading, loadCategories, selectedCategory, setSelectedCategory } =
    useCategory();
  return (
    <CategoryListPopover
      categories={categories}
      loadMoreCategories={loadCategories!}
      onCategorySelect={setSelectedCategory!}
      selectedCategory={selectedCategory}
      loading={loading}
    />
  );
};
