import { useContext } from "react";
import { StockContext } from "../catalogue/stock-provider";

const useStocks = () => {
  const stockContext = useContext(StockContext);
  return {
    ...stockContext,
  };
};

export default useStocks;
