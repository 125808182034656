import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";

interface  Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
export  const CreateJobConfirmationDialog: React.FC<Props> = ({
    isOpen,
    onClose,
    onConfirm
                                                              }) => {

  return <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Are you sure ?</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        This will create a job and lock the selected items.
      </ModalBody>

      <ModalFooter>
        <Button variant='ghost' mr={3} onClick={onClose}>
          Close
        </Button>
        <Button onClick={onConfirm} colorScheme='blue'>Confirm</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}
