import {Center, Card, Text, Flex, Heading, Button} from "@chakra-ui/react";
import {Formik, Form} from "formik";
import React from "react";
import TextField from "../../components/TextField";

export const ResetPassword: React.FC<{
  updatePassword: (password: string) => void;
}> = ({updatePassword}) => {
  return (
      <Center height="100vh" width="100vw" background="#000">
        <Card height="60%" width="60%" background="#808080" color="#fff">
          <Formik
              initialValues={{
                password: "",
              }}
              onSubmit={(values) => {
                updatePassword(values.password);
              }}
          >
            {(formikProps) => (
                <Form onSubmit={formikProps.handleSubmit}>
                  <Center>
                    <Flex
                        flexDirection="column"
                        alignItems="center"
                        justifyContent={"center"}
                        padding="30px"
                        width="50%"
                    >
                      <Heading margin="30px">Update Password</Heading>
                      <Text textAlign="center" marginBottom="20px">
                        Welcome!! You need to reset your password!!
                      </Text>
                      <TextField
                          label="Password"
                          name="password"
                          type="password"
                          value={formikProps.values.password}
                          onChange={formikProps.handleChange}
                      />
                      <Button type="submit" mt={"8px"}>Reset Password</Button>
                    </Flex>
                  </Center>
                </Form>
            )}
          </Formik>
        </Card>
      </Center>
  );
};
