import useAuth from "../../hooks/useAuth"
import Login from "../pages/Login"




export const LoginView = () => {

    const auth = useAuth();

    return <Login login={auth.login!} />
}