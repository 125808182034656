import {useParams} from "react-router";
import {ProjectView} from "./ProjectView";


export const AddProject = () => {
  return <ProjectView/>
}


export const EditProject = () => {
  const params = useParams<{ id: string }>();
return <ProjectView jobId={+params.id!}/>
}
