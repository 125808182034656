import React from 'react';
import {Center, FormLabel, Heading, HStack, Spinner, Switch} from "@chakra-ui/react";
import {IDamageReport, IDamageReportEvent} from "../../models/damageReports";
import {IUser, Role} from "../../models/user";
import DamageReportCard from "./DamageReportCard";

interface DamageReportPageProps {
  damageReports: IDamageReport[];
  loading: boolean;
  showArchived: boolean;
  toggleArchived: () => void;
  showEvents: (events: IDamageReportEvent[]) => void;
  user: IUser;
  updateDamageReport: (stockId: number, update: Partial<IDamageReport>) => void;
  deleteDamageReport: (stockId: number) => void;
}

const DamageReportsPage: React.FC<DamageReportPageProps> = (props: DamageReportPageProps) => {
  const {
    loading,
    damageReports,
    user,
    updateDamageReport,
    deleteDamageReport,
    showArchived,
    toggleArchived,
    showEvents
  } = props;
  return (
      <>
        <HStack justifyContent='space-between'>
          <Heading size="lg">Damage Reports</Heading>
          <HStack>
            <FormLabel>Archived</FormLabel>
            <Switch
                isChecked={showArchived}
                onChange={() => toggleArchived()}
            />
          </HStack>
        </HStack>
        {
          loading ? <Spinner/> :
              damageReports && damageReports.length > 0 ?
                  damageReports.map((damageReport) => (
                      <DamageReportCard
                          key={damageReport.id}
                          damageReport={damageReport}
                          disabled={user.role !== Role.SUPER_ADMIN}
                          updateDamageReport={updateDamageReport}
                          deleteDamageReport={deleteDamageReport}
                          showEvents={showEvents}
                      />
                  )) :
                  <Center>
                    No damage reports found!
                  </Center>
        }
      </>


  );
};

export default DamageReportsPage;