export interface IJob {
  id: number;
  name: string;
  companyId: number;
  setupDate?: Date;
  finishDate?: Date;
  liveDate?: Date;
  address?: string;
  w3wLocation?: string;
  poNumber?: string;
  note?: string;
  total?: number;
  status: JobStatus;
  stocks: { stockId: number, quantity: number }[];
}

export interface IJobResponse {
  id: number;
  name: string;
  companyId: number;
  setupDate?: string;
  finishDate?: string;
  liveDate?: string;
  address?: string;
  w3wLocation?: string;
  poNumber?: string;
  note?: string;
  total?: string;
  status: JobStatus;
  stocks: { stockId: number, quantity: number }[];
}

export enum JobStatus {
  DRAFT = 'DRAFT',
  CREATED = 'CREATED',
  CONFIRMED = 'CONFIRMED',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED'
}

export const getStatusColor = (status: JobStatus) => {
  switch (status) {
    case JobStatus.DRAFT:
      return 'orange';
    case JobStatus.CREATED:
      return 'blue';
    case JobStatus.CONFIRMED:
      return 'lightgreen';
    case JobStatus.FINISHED:
      return 'darkgreen';
    case JobStatus.CANCELLED:
      return 'red';
  }
}
