import {Avatar, Box, HStack, Image, Stack} from "@chakra-ui/react";
import logo from "../assets/foxWylie.png";
import { FaShoppingBag } from "react-icons/fa/index";
import { NavLink } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export const Header = () => {
  const {company} = useAuth();
  return (
    <Stack direction="row" justifyContent="space-between" padding="20px">
      <HStack gap={0} >
        <Box width="20vw" as={NavLink} to={"/"}>
          <Image height="50px" objectFit="cover" src={logo} />
        </Box>
        {
          company && company.logo && <Image height="40px" objectFit="cover" src={company.logo} />
        }

      </HStack>
      <Stack direction="row" alignItems="center">
        <Avatar size="sm" as={NavLink} to={"/accounts"} />
        <Box marginLeft="10px">
          <FaShoppingBag size="24px" />
        </Box>
      </Stack>
    </Stack>
  );
};
