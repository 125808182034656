import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import {IUser, ISignupSchema, Role} from "../../models/user";
import {Formik, FormikHelpers, FormikValues} from "formik";
import * as Yup from "yup";
import {getDisplayName, getRoleName} from "../../utils";
import useAuth from "../../hooks/useAuth";

interface Props {
  users: IUser[];
  loading: boolean;
  createUser: (schema: ISignupSchema) => void;
  resetPassword: (userId:number) => void;
}

export const AdminControl: React.FC<Props> = ({users, loading, createUser, resetPassword}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const initialRef = React.useRef(null);
  const {user} = useAuth();
  const schema = Yup.object().shape({
    name: Yup.string().required("Name cannot be empty."),
    email: Yup.string().email().required("Enter a valid email."),
  });
  return (
      <Box>
        <Box height="100%">
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Job Role</Th>
                  <Th>Email</Th>
                  {user?.role !== Role.USER && <Th>Action</Th>}

                </Tr>
              </Thead>
              <Tbody>
                {loading && <Box>Loading...</Box>}
                {users.map((e) => {
                  return (
                      <Tr key={e.id}>
                        <Td>{getDisplayName(e)}</Td>
                        <Td>{getRoleName(e.role)}</Td>
                        <Td>{e.email}</Td>
                        {
                            user?.role !== Role.USER &&
                            <Td><Button
                                size={"sm"}
                                variant={"ghost"}
                                color={"orange"}
                                onClick={() => resetPassword(e.id)}
                            >Reset Password</Button></Td>
                        }

                      </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Button
            colorScheme="blue"
            position="fixed"
            bottom="20px"
            right="20px"
            onClick={onOpen}
        >
          Add User
        </Button>
        <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay/>
          <Formik
              initialValues={{
                name: "",
                email: "",
                role: "USER",
              }}
              validationSchema={schema}
              onSubmit={function (
                  values: FormikValues,
                  formikHelpers: FormikHelpers<FormikValues>
              ): void | Promise<any> {
                createUser({
                  name: values.name,
                  email: values.email,
                  role: values.role,
                });
                onClose();
              }}
          >
            {(formikProps) => {
              return (
                  <form onSubmit={formikProps.handleSubmit}>
                    <ModalContent>
                      <ModalHeader>Create your account</ModalHeader>
                      <ModalCloseButton/>
                      <ModalBody pb={6}>
                        <FormControl>
                          <FormLabel>Name</FormLabel>
                          <Input
                              ref={initialRef}
                              placeholder="Name"
                              name="name"
                              onChange={formikProps.handleChange}
                              value={formikProps.values.name}
                          />
                        </FormControl>

                        <FormControl mt={4}>
                          <FormLabel>Email</FormLabel>
                          <Input
                              placeholder="Email"
                              type="email"
                              onChange={formikProps.handleChange}
                              name="email"
                              value={formikProps.values.email}
                          />
                        </FormControl>
                        <FormControl mt={4}>
                          <FormLabel>Role</FormLabel>
                          <Select
                              placeholder="Select role"
                              name="role"
                              value={formikProps.values.role}
                              onChange={formikProps.handleChange}
                          >
                            <option value="USER">User</option>
                            <option value="ADMIN">Admin</option>
                            <option value="MANAGER">Manager</option>
                          </Select>
                        </FormControl>
                      </ModalBody>

                      <ModalFooter>
                        <Button colorScheme="blue" mr={3} type="submit">
                          Save
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                      </ModalFooter>
                    </ModalContent>
                  </form>
              );
            }}
          </Formik>
        </Modal>
      </Box>
  );
};
