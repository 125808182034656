import {configureStore} from '@reduxjs/toolkit'
import {categoryReducer} from "./categorySlice";
import {stocksReducer} from "./stockSlice";
import {apiSlice} from "./apiSlice";


export const store = configureStore({
  reducer: {
    categories: categoryReducer,
    stocks: stocksReducer,
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({serializableCheck: false}).concat(apiSlice.middleware)
})

export const rootState = store.getState();
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;



