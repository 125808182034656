import React from "react";
import {
  IconButton,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";
import {useGetStockByIdQuery} from "../../redux/stores/apiSlice";
import {DeleteIcon} from "@chakra-ui/icons";


export const CartItem: React.FC<{ stockId: number,removeItem: () => void; quantity: number }> = ({stockId, quantity, removeItem}) => {
  const {data: stock, isLoading} = useGetStockByIdQuery(stockId);
  if(isLoading) {
    return <Tr>
      <Td><Skeleton height={'14px'}/></Td>
      <Td><Skeleton height={'14px'}/></Td>
    </Tr>
  }
  return <>
    <Tr fontSize={12}>
      <Td>{stock?.name}</Td>
      <Td isNumeric>{quantity}</Td>
      <Td isNumeric>
        <IconButton size={"xs"} aria-label={'Delete'} color={'red'} icon={<DeleteIcon fontSize={12}/>} onClick={removeItem}/>
      </Td>
    </Tr>
    {
        stock?.virtual && <TableContainer ml={'30px'}>
        <Table size={'xs'}>
            <Tbody>
              {
                stock.autopulls.map(e => <Tr fontSize={12}>
                  <Td>{e.name}</Td>
                  <Td isNumeric>{e.qty}</Td>
                </Tr>)
              }
            </Tbody>
        </Table>
        </TableContainer>
    }
  </>;
}
