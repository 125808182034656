import {
  Box,
  Flex,
  Heading,
} from "@chakra-ui/react";
import React, {PropsWithChildren} from "react";
import SidenavItems from "./SideNavItems";
import {Outlet} from "react-router";

export const EquipmentCatalogueContainer: React.FC<PropsWithChildren> = ({
}) => {
  return (
    <Flex height="calc(100% - 100px)">
      <Box width="20%" marginRight="20px">
        <Heading padding={3}>Events</Heading>
        <SidenavItems
          navItems={[
            {
              label: "Dashboard",
              to: "/catalogue/dashboard",
            },
            {
              label: "Equipment Catalogue",
              to: "/catalogue/stocks",
            },
            {
              label: "Jobs",
              to: "/catalogue/projects",
            },
            {
              label: "Damage reports",
              to: "/catalogue/damage-reports",
            },
          ]}
        />
      </Box>
      <Box width="80%"  padding={"12px 20px"}><Outlet/></Box>
    </Flex>
  );
};
