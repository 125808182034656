import React from "react";
import { Button, Card, Center, Flex, Heading, Spacer } from "@chakra-ui/react";
import TextField from "../../components/TextField";
import { Form, Formik } from "formik";
interface LoginProps {
  login: (email: string, password: string) => void;
}
const Login: React.FC<LoginProps> = ({ login }) => {
  return (
    <Center height="100vh" width="100vw" background="#000">
      <Card height="60%" width="60%" background="#808080" color="#fff">
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={(values) => {
            login(values.email, values.password);
          }}
        >
          {(formikProps) => (
            <Form>
              <Center>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent={"center"}
                  padding="30px"
                  width="50%"
                >
                  <Heading>Admin Login</Heading>
                  <Spacer></Spacer>
                  <TextField
                    label="Email"
                    name="email"
                    type="email"
                    value={formikProps.values.email}
                    onChange={formikProps.handleChange}
                  />
                  <TextField
                    label="Password"
                    name="password"
                    type="password"
                    value={formikProps.values.password}
                    onChange={formikProps.handleChange}
                  />
                  <Button type="submit" mt={"8px"}>Login</Button>
                  {/* <Text fontSize={12}>
                    Don't have a account ?{" "}
                    <Link as={ReactRouterLink} to="/signup">
                      Sign up
                    </Link>
                  </Text> */}
                </Flex>
              </Center>
            </Form>
          )}
        </Formik>
      </Card>
    </Center>
  );
};

export default Login;
