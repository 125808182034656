import React from "react";
import {Box, Card, Heading, HStack, Text, VStack} from "@chakra-ui/react";
import {JobStatus, getStatusColor} from "../models/job";
import {useDashboardQuery} from "../redux/stores/apiSlice";
import {useNavigate} from "react-router";

export const Dashboard : React.FC = () => {
  const {data} = useDashboardQuery(null);
  const navigate = useNavigate();
  const openJobs = () => {
    navigate('/catalogue/projects');
  }
  const openDamageReports = () => {
    navigate('/catalogue/damage-reports');
  }
  return <>
    <HStack p={10} w={"100%"} justifyContent={"space-between"}>
      <VStack gap={2} alignItems={"flex-start"}>
        <Heading size={"md"}>Confirmed Jobs</Heading>
        <Card onClick={openJobs} cursor={"pointer"} pl={10} overflow={"hidden"} mr={10} color="#fff" bg='#808080' width="100%" height={100}
              display={"flex"} justify={'center'} alignItems={"center"}>
          <HStack w='100%' h={'100%'} justifyContent={"space-between"} alignItems={"stretch"}>
            <VStack justifyContent={"center"} alignItems={"flex-start"} h={'100%'}>
              <Box>{data?.jobs.find(j => j.status === JobStatus.CONFIRMED)!.jobCount}</Box>
              <Box>No of Jobs</Box>
            </VStack>
            <VStack justifyContent={"center"} alignItems={"flex-start"} h={'100%'}>
              <Box>£ {data?.jobs.find(j => j.status === JobStatus.CONFIRMED)!.totalCost}</Box>
              <Box>Total Cost</Box>
            </VStack>
            <Box w={'80px'} h="100%" bg={getStatusColor(JobStatus.CONFIRMED)}></Box>
          </HStack>

        </Card>
        <Heading size={"md"}>UnConfirmed / Draft Jobs</Heading>
        <Card onClick={openJobs} cursor={"pointer"} pl={10} overflow={"hidden"} mr={10} color="#fff" bg='#808080' width="100%" height={100}
              display={"flex"} justify={'center'} alignItems={"center"}>
          <HStack w='100%' h={'100%'} justifyContent={"space-between"} alignItems={"stretch"}>
            <VStack justifyContent={"center"} alignItems={"flex-start"} h={'100%'}>
              <Box>{(data?.jobs.find(j => j.status === JobStatus.CREATED)!.jobCount ??0) + (data?.jobs.find(j => j.status === JobStatus.DRAFT)!.jobCount ?? 0)}</Box>
              <Box>No of Jobs</Box>
            </VStack>
            <VStack justifyContent={"center"} alignItems={"flex-start"} h={'100%'}>
              <Box>£ {+(data?.jobs.find(j => j.status === JobStatus.CREATED)!.totalCost ??0) + +(data?.jobs.find(j => j.status === JobStatus.DRAFT)!.totalCost ?? 0)}</Box>
              <Box>Total Cost</Box>
            </VStack>
            <Box w={'80px'} h="100%" bg={getStatusColor(JobStatus.DRAFT)}></Box>
          </HStack>

        </Card>
        <Heading size={"md"}>Completed Jobs</Heading>
        <Card onClick={openJobs} cursor={"pointer"} pl={10} overflow={"hidden"} mr={10} color="#fff" bg='#808080' width="100%" height={100}
              display={"flex"} justify={'center'} alignItems={"center"}>
          <HStack w='100%' h={'100%'} justifyContent={"space-between"} alignItems={"stretch"}>
            <VStack justifyContent={"center"} alignItems={"flex-start"} h={'100%'}>
              <Box>{data?.jobs.find(j => j.status === JobStatus.FINISHED)!.jobCount}</Box>
              <Box>No of Jobs</Box>
            </VStack>
            <VStack justifyContent={"center"} alignItems={"flex-start"} h={'100%'}>
              <Box>£ {data?.jobs.find(j => j.status === JobStatus.FINISHED)!.totalCost}</Box>
              <Box>Total Cost</Box>
            </VStack>
            <Box w={'80px'} h="100%" bg={getStatusColor(JobStatus.FINISHED)}></Box>
          </HStack>

        </Card>
      </VStack>
      <VStack justifyContent={"flex-start"}>
        <Card onClick={openDamageReports} cursor={"pointer"} p="10px" color="#fff" bg='#808080'>
          <VStack>
            <Heading>Damage Report</Heading>
            <Text>{data?.damageReports ?? 0}</Text>
            <Text>No. of Items</Text>
          </VStack>
        </Card>
      </VStack>
    </HStack>
  </>

}
