export interface IUser {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
  role: Role;
  companyId: number;
}

export enum Role {
  SUPER_ADMIN = "SUPER_ADMIN",
  MANAGER = "MANAGER",
  ADMIN = "ADMIN",
  USER = "USER",
}

export interface ISignupSchema {
  name: string;
  email: string;
  role: Role;
}
