import { Button, Center, Flex, Text } from "@chakra-ui/react";
import { AccountsContainer } from "../../components/AccountsContainer";
import React from "react";
import { IUser } from "../../models/user";

interface AccountDetailsProps {
  user?: IUser;
  logout: () => void;
}
export const AccountDetails: React.FC<AccountDetailsProps> = ({
  user,
  logout,
}) => {
  return (
    <>
      <Center height="100%" flexDirection="column" gap={12}>
        <Flex
          width="50%"
          direction="column"
          justifyContent="space-evenly"
          minHeight="30%"
          borderRadius="20px"
          padding="20px"
          border="1px solid black"
        >
          <DataRow label="Name" value={user?.name} />
          <DataRow label="Email Address" value={user?.email} />
          <DataRow label="Phone Number" value={user?.phoneNumber} />
        </Flex>
        <Button colorScheme="red" onClick={logout}>
          Logout
        </Button>
      </Center>
    </>
  );
};

const DataRow: React.FC<{ label: string; value?: string }> = ({
  label,
  value,
}) => {
  return (
    <Flex margin="5px">
      <Text fontWeight={600}>{label}</Text>: {value ?? ""}
    </Flex>
  );
};
