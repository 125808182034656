import {ProjectList} from "../pages/ProjectList";
import {useLoadJobsQuery} from "../../redux/stores/apiSlice";
import {useNavigate} from "react-router";
import {useState} from "react";
import {ChatContainer} from "./ChatContainer";
import {useDisclosure} from "@chakra-ui/react";

export const ProjectListView = () => {
  const {data, isLoading} = useLoadJobsQuery(null);
  const navigate = useNavigate();
  const {isOpen, onClose, onOpen} = useDisclosure();
  const [chatJobId, setChatJobId] = useState<number>();
  const openChat = (id: number) => {
    setChatJobId(id);
    onOpen();
  }
  return <>
    <ProjectList onAddNew={() => {
      navigate('/projects/new');
    }} onEdit={(id) => {
      navigate(`/projects/${id}`);
    }} loading={isLoading} jobs={data|| []} openChat={openChat}/>
    <ChatContainer jobId={chatJobId} isOpen={isOpen} onClose={onClose}/>
  </>
}
