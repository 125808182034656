import React, {useRef} from "react";
import {Center, Tooltip} from "@chakra-ui/react";
import {FaPlus} from "react-icons/all";
import {DropzoneOptions, useDropzone} from "react-dropzone";

interface Props {
  onImagesSelect: (files: File) => void;
}
export const ImagePicker: React.FC<Props> = ({onImagesSelect}) => {

  const uploadRef = useRef<HTMLInputElement | null>(null);
  const onSelect = React.useCallback((files: File[]) => {
    if (files) {
      onImagesSelect(files[0]);
    }
  }, [onImagesSelect]);
  const {  getInputProps } =
      useDropzone({ onDrop: onSelect } as DropzoneOptions);
  return <Tooltip label="Add Images">
    <Center cursor={"pointer"} onClick={(event) => {
      event.preventDefault();
      event.stopPropagation();
      if (uploadRef && uploadRef.current) {
        uploadRef.current.click();
      }
    }} border="1px" height="50px"
            width="50px">
      <FaPlus/>
      <input multiple={false} {...getInputProps()} ref={uploadRef} type='file' accept='image/*'
             style={{display: 'none'}}/>
    </Center>
  </Tooltip>
}
