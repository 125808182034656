import React from "react";
import {Box, Button, Text, Heading, Input, InputGroup, InputRightElement, VStack} from "@chakra-ui/react";
import {IMessage} from "../../models/message";
import useAuth from "../../hooks/useAuth";
import {Form, Formik} from "formik";


interface Props {
  messages?: IMessage[];
  loading: boolean;
  sendChat: (text: string) => void;
}
export const ChatComponent: React.FC<Props> = ({messages, loading, sendChat}) => {
  const {user} = useAuth();
  return <Box w={'100%'}>
    <Heading>Chat Wall</Heading>
    <Box position={'relative'} w={'100%'} border="1px solid black" height={550}>
      <VStack w={'100%'}>
        {messages?.map(m => {
          return <VStack p={'10px'} gap={1} alignSelf={m.userId !== user?.id ? 'flex-start': 'flex-end'}>
            <Heading size={'sm'}>{m.user?.name ?? ''}</Heading>
            <Text>{m.text}</Text>
          </VStack>
        })}
      </VStack>
      <Formik initialValues={{
        text: ''
      }} validate={(values) => {
        if(values.text === "")
        return {
          text: 'Message Can\'t be empty'
        }
      }} onSubmit={({text}) => {
        sendChat(text);
      }}>
        {
          (formikProps) => {
            return <Form>
              <Box position={"absolute"} bottom={0} right={0} left={0}>
                <InputGroup size='md'>
                  <Input
                      pr='4.5rem'
                      placeholder='Enter message'
                      name="text"
                      isInvalid={!formikProps.isValid}
                      value={formikProps.values.text}
                      onChange={formikProps.handleChange}
                  />

                  <InputRightElement width='4.5rem'>
                    <Button disabled={!formikProps.isValid} h='100%' size='sm' type={'submit'}>
                      Submit
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Box>
            </Form>
          }
        }
      </Formik>
    </Box>
  </Box>
}
