import {ChatComponent} from "../components/ChatComponent";
import React, {useEffect} from "react";
import {useGetJobChatQuery, useSendJobChatMutation} from "../../redux/stores/apiSlice";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay, useToast
} from "@chakra-ui/react";

export const ChatContainer: React.FC<{ jobId?: number, isOpen: boolean, onClose: () => void; }> = ({
                                                                                                     jobId,
                                                                                                     isOpen,
                                                                                                     onClose
                                                                                                   }) => {
  const {data, isLoading} = useGetJobChatQuery(
      jobId!,
      {
        skip: !jobId,
        refetchOnFocus: true
      });
  const toast = useToast();
  const [sendJobChat, {isSuccess}] = useSendJobChatMutation();
  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Sent Chat!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast, isSuccess]);
  const sendChat = async (text: string) => {
    await sendJobChat({id: jobId!, text});

  }
  return <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay/>
    <ModalContent>
      <ModalCloseButton/>
      <ModalBody>
        <ChatComponent sendChat={sendChat} messages={data} loading={isLoading}/>
      </ModalBody>
    </ModalContent>
  </Modal>
}
