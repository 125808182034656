import {Role} from "../../../models/user";
import {Box, Select} from "@chakra-ui/react";
import React from "react";
import useAuth from "../../../hooks/useAuth";
import {useGetCompaniesQuery} from "../../../redux/stores/apiSlice";


export const OwnerSelector: React.FC<{ownerId?: number, disabled?: boolean, setOwner: (id: number) => void }> = ({setOwner, disabled, ownerId}) => {
  const {user} = useAuth();
  const {data} = useGetCompaniesQuery(null);
  return <Box padding="16px">
    {user?.role === Role.SUPER_ADMIN && <Box>
        <Select disabled={disabled} value={ownerId} placeholder='Select Company' onChange={(e) => setOwner!(+e.target.value)}>
          {
            data?.map(e => {
              return <option key={e.id} value={e.id}>{e.name}</option>
            })
          }
        </Select>
    </Box>}
  </Box>
}
