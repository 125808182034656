import * as Axios from "axios";
import { useToast } from "@chakra-ui/react";
import { Config } from "../config";

export interface RequestOptions {
  useAuthHeaders?: boolean;
  useBaseUrl?: boolean;
  headers?: any;
  axiosParams?: any;
}

export class HttpError extends Error {
  constructor(public message: string, private status: number) {
    super(message);
  }
}

// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsIm93bmVyIjoib25saW5lLWNoYWktd2FsYSIsInRva2VuS2V5IjoieHl0bnUyIiwiaWF0IjoxNjUwMTA5OTk0fQ.OpOFX-tWgQdibcNN_lEtuH6tv4Chzn0HYC-W4nDYn-g

const useApi = () => {
  const toast = useToast();

  const getUrl = (relativePath: string, config?: RequestOptions) => {
    if (config && config.useBaseUrl === false) {
      return relativePath;
    }
    return Config.API_BASE_URL + relativePath;
  };

  const getHeaders = async (config?: RequestOptions) => {
    if (config && config.useAuthHeaders === false) {
      return config.headers;
    }
    const token = await getToken();
    return {
      ...(config || {}).headers,
      Authorization: `${token}`,
    };
  };

  const get = async (url: string, params?: any, config?: RequestOptions) => {
    return Axios.default
      .get(getUrl(url, config), {
        ...config?.axiosParams,
        params: params || null,
        headers: await getHeaders(config),
      })
      .then((response) => response.data)
      .catch(handleError);
  };

  const post = async (url: string, body: any, config?: RequestOptions) => {
    return Axios.default
      .post(getUrl(url, config), body, {
        headers: await getHeaders(config),
      })
      .then((response) => response.data)
      .catch(handleError);
  };

  const put = async (url: string, body: any, config?: RequestOptions) => {
    return Axios.default
      .put(getUrl(url, config), body, {
        headers: await getHeaders(config),
      })
      .then((response) => response.data)
      .catch(handleError);
  };

  const patch = async (url: string, body: any, config?: RequestOptions) => {
    return Axios.default
      .patch(getUrl(url, config), body, {
        headers: await getHeaders(config),
      })
      .then((response) => response.data)
      .catch(handleError);
  };

  const destroy = async (url: string, body?: any, config?: RequestOptions) => {
    return Axios.default
      .delete(getUrl(url, config), {
        headers: await getHeaders(config),
        data: body,
      })
      .then((response) => response.data)
      .catch(handleError);
  };

  const handleError = (error: any) => {
    const genericErrorMessage = "Something went wrong. Try again later!";
    const message = error.response?.data?.message || genericErrorMessage;
    const status = error.response?.status;
    console.log(error);
    if (status !== 401) {
      toast({
        title: "Error",
        description: message,
      });
    }
    throw new HttpError(message, status);
  };

  const setToken = (token: string) => {
    return localStorage.setItem("secure_token", token);
  };

  const deleteToken = () => {
    return localStorage.removeItem("secure_token");
  };

  const getToken = () => {
    return localStorage.getItem("secure_token");
  };
  const getPreSignedUrl = async (): Promise<string> => {
    const res =  await get('/preSignedUrl?key=stock-images');
    return res.url;
  }

  const upload = async (url: string, file: File): Promise<string> => {
    return await Axios.default.put(url, file, {headers: {
        'Content-Type': 'image/png'
      }} );
  }
  return {
    get,
    post,
    put,
    patch,
    destroy,
    setToken,
    getToken,
    deleteToken,
    getPreSignedUrl,
    upload
  };
};

export default useApi;
