import React from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import {IDamageReportEvent} from "../../models/damageReports";
import {getDisplayName, getEventColor} from "../../utils";

interface DamageReportEventsProps {
  isOpen: boolean;
  onClose: () => void;
  events: IDamageReportEvent[]
}

const DamageReportEvents: React.FC<DamageReportEventsProps> = (props: DamageReportEventsProps) => {
  return (
      <Modal isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay/>
        <ModalContent bg='black' color='white'>
          <ModalHeader>Events</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            {
              props.events.map((event: IDamageReportEvent, index) =>
                  <Text fontSize='14px' display='block' key={event.id}>
                    <Text as='span' display='inline'
                          fontWeight="bold">{new Date(event.createdAt).toLocaleDateString()}</Text>:{" "}
                    {getDisplayName(event.user)} {" "}
                    <Text as='span' fontWeight='bold' display='inline'
                          color={getEventColor(event.action)}>{event.action}</Text> this report.
                  </Text>
              )
            }

          </ModalBody>
          <ModalFooter/>
        </ModalContent>

      </Modal>
  );
};

export default DamageReportEvents;