import { useContext } from "react";
import { CategoryContext } from "../catalogue/category-provider";

const useCategory = () => {
    const categoryContext = useContext(CategoryContext);
    return {
        ...categoryContext,
    };
};

export default useCategory;
