import React, {useEffect, useState} from "react";
import {
  Box,
  HStack,
  Text,
  Heading,
  VStack,
  Input,
  Button,
  InputLeftElement,
  InputGroup,
  TableContainer,
  Table,
  Tbody,
  TabList,
  Tab,
  Tabs,
  TabPanels,
  TabPanel,
  Center,
  Td,
  Th,
  Thead,
  Tr,
  IconButton,
  Textarea,
  TableCaption
} from "@chakra-ui/react";
import {ChatContainer} from "../views/ChatContainer";
import {FaSearch} from "react-icons/fa";
import {useSelector} from "react-redux";
import {selectStockQuery} from "../../redux/stores/stockSlice";
import {IStock} from "../../models/stock";
import {StockCard} from "../components/StockCard";
import {IJob, JobStatus} from "../../models/job";
import {CartItem} from "../components/CartItem";
import {Form, Formik} from "formik";
import TextField from "../../components/TextField";
import {omit, uniqBy} from "lodash";
import useAuth from "../../hooks/useAuth";
import {Role} from "../../models/user";
import {OwnerSelector} from "../../catalogue/pages/components/OwnerSelector";
import {ChatIcon, ChevronLeftIcon} from "@chakra-ui/icons";
import {useLazyGetStockByIdQuery} from "../../redux/stores/apiSlice";

interface Props {
  job?: Partial<IJob>;
  onSearch: (value: string) => void;
  stocks: IStock[];
  selectedStocks: { stockId: number, quantity: number }[];
  addStock: (stockId: number, qty: number) => void;
  totalCost: number;
  removeStock: (od: number) => void;
  createJob: (job: Partial<IJob>) => void;
  onLoadMoreStocks: () => void;
  setOwner: (owner: string) => void;
  goBack: () => void;
  openChat: (id: number) => void;
}

export const CreateProject: React.FC<Props> = ({
                                                 job,
                                                 onSearch,
                                                 createJob,
                                                 removeStock,
                                                 stocks,
                                                 addStock,
                                                 selectedStocks,
                                                 onLoadMoreStocks,
                                                 setOwner,
                                                 goBack,
                                                 openChat,
                                                 totalCost
                                               }) => {
  const queryStock = useSelector(selectStockQuery);
  const {user} = useAuth();
  console.log(totalCost);
  const handleScroll = (element: any) => {
    const bottom =
        Math.abs(
            element.target.scrollHeight -
            (element.target.scrollTop + element.target.clientHeight)
        ) <= 1;
    if (bottom) {
      onLoadMoreStocks();
    }
  };
  const [query, setQuery] = useState(queryStock);
  useEffect(() => {
  }, [totalCost]);
  return <Formik
      initialValues={{
        name: job?.name || "",
        setupDate: job?.setupDate,
        finishDate: job?.finishDate,
        liveDate: job?.liveDate,
        address: job?.address || "",
        w3wLocation: job?.w3wLocation || "",
        poNumber: job?.poNumber || "",
        note: job?.note || "",
        companyId: job?.companyId || user?.companyId,
        total: job?.total || totalCost || 0,
        stocks: selectedStocks,
        status: job?.status || JobStatus.DRAFT
      }}
      onSubmit={async (values) => {
        createJob({
          ...values,
          stocks: selectedStocks,
          total: totalCost
        });
      }}>
    {(formikProps) =>
        <Form>
          <HStack alignItems={"flex-start"} width="100vw" spacing={5} justifyContent={"space-around"}>
            <VStack gap={2} width="100%" height="100vh" p={'10px'} alignItems={"stretch"}>
              <HStack width="100%">
                <ChevronLeftIcon fontSize={24} cursor={"pointer"} onClick={goBack}/>
                <Text fontWeight={"bold"} w="20%">PO No.</Text>
                <TextField disabled={!!job?.poNumber} placeholder={'Enter PO Number'} name={'poNumber'}
                           value={formikProps.values.poNumber}
                           onChange={formikProps.handleChange}/>
                <Button w="20%" onClick={() => {
                  formikProps.setFieldValue('status', JobStatus.CONFIRMED);
                  formikProps.handleSubmit();
                }}>Submit</Button>
              </HStack>
              <Heading size={"md"}>Available Equipments</Heading>
              <Text>Below are the items available at no cost to your restaurant. You will be charged for the
                transportation
                and installation <br/>(if required) for all items.</Text>
              <Tabs onChange={(index) => {
                if (index === 0) {
                  setOwner('Nandos');
                } else {
                  setOwner('Foxwylie')
                }
              }} variant='soft-rounded' colorScheme={"orange"}>
                <TabList>
                  <Tab value={'Nandos'}>Nandos</Tab>
                  <Tab value={'Foxwylie'}>To Hire</Tab>
                </TabList>
              </Tabs>
              <InputGroup padding={3}>
                <InputLeftElement
                    pointerEvents="none"
                    display="flex"
                    left="auto"
                    top="auto"
                    right="auto"
                >
                  <FaSearch color="gray.300"/>
                </InputLeftElement>
                <Input
                    type="text"
                    placeholder="Search"
                    borderRadius="50px"
                    value={query}
                    onChange={(e) => {
                      setQuery(e.target.value);
                      if (!!onSearch) {
                        onSearch(e.target.value);
                      }
                    }}
                />
              </InputGroup>

              <Box overflowY="scroll" height="calc(100vh - 180px)" onScroll={handleScroll}>
                {stocks?.map(stock => {
                  const selectedStock = selectedStocks.find(s => s.stockId === stock.id);
                  return <StockCard
                      key={stock.id}
                      removeStock={removeStock}
                      selectedStock={selectedStock} stock={stock}
                      addStock={(stockId, qty) => {
                        const sS = selectedStocks.filter(s => s.stockId !== stockId);
                        formikProps.setFieldValue('stocks',  uniqBy([...sS, {
                          stockId,
                          quantity: qty
                        }], s => s.stockId));
                        addStock(stockId, qty);
                      }}/>
                })}
              </Box>
            </VStack>
            <VStack width="100%" padding={'10px'} gap={2}>
              <HStack width="100%" justifyContent={"space-between"}>
                <Heading size={'md'}>New Job</Heading>
                <HStack gap={2}>
                  <Button type={'button'} onClick={() => {
                    formikProps.setFieldValue('status', JobStatus.CREATED);
                    formikProps.handleSubmit();
                  }}>Submit</Button>
                  <Button type={'button'} onClick={() => {
                    formikProps.setFieldValue('status', JobStatus.DRAFT);
                    formikProps.handleSubmit();
                  }}>Save Draft</Button>
                </HStack>
              </HStack>
              <HStack width={'100%'} gap={2}>
                <Box w={user?.role === Role.SUPER_ADMIN ? '30%' : '100%'}>
                  <TextField disabled={!!job?.poNumber} placeholder="Project Name" name={'name'}
                             value={formikProps.values.name}
                             onChange={formikProps.handleChange}/>
                </Box>
                {
                    user?.role === Role.SUPER_ADMIN &&
                    <OwnerSelector disabled={!!job?.poNumber} ownerId={job?.companyId} setOwner={(id) => {
                      formikProps.setFieldValue('companyId', id);
                    }}/>
                }
              </HStack>
              <TextField disabled={!!job?.poNumber} placeholder="Address" name={'address'}
                         value={formikProps.values.address}
                         onChange={formikProps.handleChange}/>
              <TextField disabled={!!job?.poNumber} placeholder="W3W Location" name={'w3wLocation'}
                         value={formikProps.values.w3wLocation}
                         onChange={formikProps.handleChange}/>
              <Textarea
                  name={'note'}
                  value={formikProps.values.note}
                  onChange={formikProps.handleChange}
                  placeholder='Add a note here...'
                  style={{
                    border: "1px solid grey",
                    borderRadius: 20,
                    padding: "20px 10px",
                    background: "#fff",
                    color: "#000"
                  }}
              />
              <HStack width={'100%'} gap={2}>
                <Box width={'30%'}>Setup Date: </Box>
                <TextField disabled={!!job?.poNumber} type="datetime-local" placeholder="Setup Date"
                           name={'setupDate'}
                           value={formikProps.values.setupDate?.toISOString().slice(0, 16)}
                           onChange={(e) => formikProps.setFieldValue('setupDate', new Date(e!.target!.value!))}/></HStack>
              <HStack width={'100%'} gap={2}>
                <Box width={'30%'}>Live Date: </Box>
                <TextField disabled={!!job?.poNumber} type="datetime-local" placeholder="Live Date"
                           name={'liveDate'}
                           value={formikProps.values.liveDate?.toISOString().slice(0, 16)}
                           onChange={(e) => formikProps.setFieldValue('liveDate', new Date(e!.target!.value!))}/></HStack>
              <HStack width={'100%'} gap={2}>
                <Box width={'30%'}>Finish Date: </Box>
                <TextField disabled={!!job?.poNumber} type="datetime-local" placeholder="Finish Date"
                           name={'finishDate'}
                           value={formikProps.values.finishDate?.toISOString().slice(0, 16)}
                           onChange={(e) => formikProps.setFieldValue('finishDate', new Date(e!.target!.value!))}/></HStack>
              <Heading size={'md'} width="100%">Item List</Heading>
              <Box width="100%" border={'1px solid black'} h={350} overflow={"scroll"}>
                {
                    selectedStocks.length === 0 && <Center h={"100%"}>
                        Add Stocks to Order
                    </Center>
                }
                {
                    selectedStocks && selectedStocks.length > 0 && <TableContainer>
                        <Table variant='striped' colorScheme={'gray'} size={"xs"}>
                            <TableCaption>Total Cost: £ {totalCost}</TableCaption>
                            <Thead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th isNumeric>
                                        Qty
                                    </Th>
                                    <Th isNumeric>
                                        Action
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                              {
                                selectedStocks.map(e =>
                                    <CartItem key={e.stockId} removeItem={() => {
                                      if (!!job?.poNumber) {
                                        return;
                                      }
                                      removeStock(e.stockId);
                                    }}
                                              stockId={e.stockId}
                                              quantity={e.quantity}
                                    />)
                              }
                            </Tbody>
                        </Table>
                    </TableContainer>
                }
              </Box>
            </VStack>
          </HStack>
          {!!job &&
              <IconButton bg={'orange'}
                          aria-label={'Chat'}
                          icon={<ChatIcon/>}
                          size={"lg"}
                          position={"fixed"}
                          right={10} bottom={10}
                          onClick={() => openChat(job!.id!)}
              />}
        </Form>}
  </Formik>
}
