import * as React from "react";
import {ChakraProvider, theme} from "@chakra-ui/react";
import AppRoutes from "./routes";
import AuthProvider from "./auth/auth-provider";
import StockProvider from "./catalogue/stock-provider";
import CategoryProvider from "./catalogue/category-provider";
import {Provider} from "react-redux";
import {store} from "./redux/stores/AppStore";

export const App = () => (
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <AuthProvider>
          <CategoryProvider>
            <StockProvider>
              <AppRoutes/>
            </StockProvider>
          </CategoryProvider>
        </AuthProvider>
      </Provider>
    </ChakraProvider>
);
