import { Box, Spinner } from "@chakra-ui/react";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { LoginView } from "./auth/views/LoginView";
import { SignUpView } from "./auth/views/SignupView";
import { AppLayout } from "./components/AppLayout";
import { AdminControlView } from "./accounts/views/AdminControlView";
import { AccountDetailsView } from "./accounts/views/AccountDetailsView";
import { EquipmentCatalogueView } from "./catalogue/views/EquipmentCatalogueView";
import { ResetPasswordView } from "./auth/views/ResetPasswordView";

import {AccountsContainer} from "./components/AccountsContainer";

import {ProjectListView} from "./jobs/views/ProjectListView";
import {EquipmentCatalogueContainer} from "./components/EquipmentContainer";
import DamageReportsView from "./damage-reports/views/DamageReportsView";
import {AddProject, EditProject} from "./jobs/views";
import {Dashboard} from "./dashboard/Dashboard";

const AppRoutes: React.FC = () => {
  const auth = useAuth();
  const isLoading = auth.tokenLoading;
  return isLoading ? (
    <Box height="100vh">
      <Spinner
        thickness="6px"
        color="brand"
        size="xl"
        pos="absolute"
        top="50%"
        left="50%"
        transform="translateY(-50%, -50%)"
      />
    </Box>
  ) : auth.isAuthenticated ? (
    <AppLayout>
      <Routes>
        <Route path="/accounts" element={<AccountsContainer/>}>
          <Route index path="/accounts/details" element={<AccountDetailsView />} />
          <Route path="/accounts/admin" element={<AdminControlView />} />
          <Route
              path="/accounts"
              element={<Navigate replace to={"/accounts/details"} />}
          />
        </Route>
        <Route path="/catalogue" element={<EquipmentCatalogueContainer />}>
          <Route path="/catalogue/stocks" element={<EquipmentCatalogueView />} />
          <Route path="/catalogue/projects" element={<ProjectListView />} />
          <Route
              path="/catalogue"
              element={<Navigate replace to={"/catalogue/stocks"} />}
          />
          <Route
              path="/catalogue/damage-reports"
              element={<DamageReportsView/>}
          />
          <Route path="/catalogue/dashboard" element={<Dashboard />} />
        </Route>
        <Route path="/projects/new" element={<AddProject />} />
        <Route path="/projects/:id" element={<EditProject />} />
        <Route path="/" element={<Navigate replace to={"/catalogue/stocks"} />} />
      </Routes>
    </AppLayout>
  ) : (
    <Routes>
      <Route path="/login" element={<LoginView />} />
      <Route path="/signup" element={<SignUpView />} />
      <Route path="/reset-password" element={<ResetPasswordView />} />
      <Route path="/" element={<Navigate replace to={"/login"} />} />
      <Route path="*" element={<Navigate replace to={"/"} />} />
    </Routes>
  );
};

export default AppRoutes;
