import {IStock} from "./stock";
import {IUser} from "./user";

export enum DamageReportStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  ARCHIVED = 'ARCHIVED'
}

export enum DamageReportEventAction {
  CREATED = 'CREATED',
  EDITED = 'EDITED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  ARCHIVED = 'ARCHIVED',
}

export interface IDamageReportEvent {
  id: number;
  action: DamageReportEventAction;
  user: IUser;
  createdAt: Date;
}

export interface IDamageReport {
  id: number;
  description: string;
  cost: number;
  status: DamageReportStatus;
  stock: IStock;
  events: IDamageReportEvent[];
  createdAt: Date;
}