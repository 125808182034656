import {ICustomCategory} from "../models/category";
import React, {
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import useApi from "../hooks/useApi";
import * as _ from "lodash";
import useAuth from "../hooks/useAuth";
import {useDispatch, useSelector} from "react-redux";
import {
  getAllCategories,
  getSelectedCategory,
  getTotal,
  loadedCategories,
  setSelectedCategory
} from "../redux/stores/categorySlice";

interface ICategoryContext {
  categories: ICustomCategory[];
  loadCategories?: () => Promise<void>;
  selectedCategory?: ICustomCategory;
  setSelectedCategory?: (v?: ICustomCategory) => void;
  loading: boolean;
}

export const CategoryContext = React.createContext<ICategoryContext>({
  categories: [],
  loadCategories: undefined,
  selectedCategory: undefined,
  setSelectedCategory: undefined,
  loading: false,
});

const CategoryProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const categories = useSelector(getAllCategories)
  const { get } = useApi();
  const dispatch = useDispatch();
  const selectedCategory = useSelector(getSelectedCategory);
  const [loading, setLoading] = useState(false);
  const total= useSelector(getTotal);
  const { user } = useAuth();
  const loadCategories = async () => {
    if (total === categories.length && total !== 0) return;
    if (loading) return;
    setLoading(true);
    const res = await get(
      `/stocks/customCategories`
    );
    const d: ICustomCategory[] = res;
    console.log(res);
    dispatch(loadedCategories(res))
    setLoading(false);
  };
  useEffect(() => {
    if (!!user) loadCategories();
  }, [user]);
  return (
    <CategoryContext.Provider
      value={{
        categories,
        loadCategories,
        selectedCategory,
        setSelectedCategory: (c) => dispatch(setSelectedCategory(c)),
        loading,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

export default CategoryProvider;
