import {EquipmentCatalogue} from "../pages/EquipmentCatalogue";
import {IStock} from "../../models/stock";
import useStocks from "../../hooks/useStocks";
import * as _ from "lodash";
import {useDisclosure} from "@chakra-ui/react";
import React, {useState} from "react";
import {EquipmentDetails} from "../pages/EquipmentDetails";
import useApi from "../../hooks/useApi";
import {useSelector} from "react-redux";
import {getAllCategories} from "../../redux/stores/categorySlice";
import {IAutoPullStock} from "../../models/autopull_stock";

export const EquipmentCatalogueView = () => {
  const {stocks, loadMoreStocks, loading, setSearchQuery, updateStock} = useStocks();
  const [stockId, setStockId] = useState<number>();
  const [virtualStock, setVirtualStock] = useState<IStock>();
  const [autopull, setAutopull] = useState<IAutoPullStock>();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {getPreSignedUrl, upload} = useApi();
  const customCategories = useSelector(getAllCategories);
  const [uploading, setUploading] = useState(false);



  const openDetails = async (stock: IStock) => {
    setStockId(stock.id);
    if(stock.virtual) {
      setVirtualStock(stock);
    } else {
      setVirtualStock(undefined);
    }
    onOpen();
  };

  const onBack = () => {
    setAutopull(undefined);
    setStockId(virtualStock!.id);
    onOpen();
  }

  const onSelectAutoPullStock = async (autoPull: IAutoPullStock) => {
    setAutopull(autoPull);
    setStockId(autoPull.parentStockId);
  }

  const updateStockFunction = async (options: { categoryIds?: number[], file?: File }, stock: IStock) => {
    const {file, categoryIds} = options;
    setUploading(true);
    let updatedUrl = stock?.imageUrl.split(',') ?? [];
    let newCategoryIds = stock?.customCategories.map(e => e.id) ?? [];
    if (file) {
      const url = await getPreSignedUrl();
      try {
        await upload(url, file);
      } catch (e) {
        setUploading(false);
      }
      updatedUrl.push(url.split('?')[0]);
    }
    if (categoryIds) {
      newCategoryIds.push(...categoryIds);
    }
    updateStock!(stock!.id, {imageUrl: updatedUrl, customCategoryIds: newCategoryIds}).finally(() => {
      setUploading(false);
    });
  }

  const debounce = _.debounce(setSearchQuery!, 200);
  return (
      <>
        <EquipmentCatalogue
            loadMoreItems={loadMoreStocks!}
            onSearch={debounce}
            items={stocks}
            loading={loading}
            openDetails={openDetails}

        />
        {
          isOpen &&
            <EquipmentDetails
                onSelectAutoPullStock={onSelectAutoPullStock}
                updateStockFunction={updateStockFunction}
                customCategories={customCategories}
                isOpen={isOpen}
                showBackButton={!!virtualStock && !!autopull}
                onBack={onBack}
                isUpdating={uploading}
                autoPullStock={autopull}
                onClose={() => {
                  setStockId(undefined);
                  setAutopull(undefined)
                  onClose();
                }}
                stockId={stockId}/>
        }

      </>
  );
};
