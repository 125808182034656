import {CreateProject} from "../pages/CreateProject";
import * as _ from "lodash";
import useStocks from "../../hooks/useStocks";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {IJob} from "../../models/job";
import {
  useCreateJobMutation,
  useGetJobByIdQuery,
  useLazyGetStockByIdQuery,
  useUpdateJobMutation
} from "../../redux/stores/apiSlice";
import {Skeleton, useDisclosure, useToast} from "@chakra-ui/react";
import {CreateJobConfirmationDialog} from "../components/CreateJobConfirmationDialog";
import {useNavigate} from "react-router";
import {ChatContainer} from "./ChatContainer";
import {selectStockOwner} from "../../redux/stores/stockSlice";

interface Props {
  jobId?: number;
}

export const ProjectView: React.FC<Props> = ({jobId}) => {
  const [selectedStocks, setSelectedStocks] = useState<{ stockId: number, quantity: number }[]>([]);
  const {data, isLoading,} = useGetJobByIdQuery(jobId!, {
    skip: !jobId,
  }, );
  const [createJob] = useCreateJobMutation();
  const [updateJob] = useUpdateJobMutation();
  const {isOpen, onClose, onOpen} = useDisclosure();
  const [job, setJob] = useState<Partial<IJob> | undefined>(data);
  const navigate = useNavigate();
  const {stocks, loadMoreStocks, setSearchQuery, setOwner} = useStocks();
  const owner = useSelector(selectStockOwner);
  const debounce = _.debounce(setSearchQuery!, 200);
  const toast = useToast();
  const [getStock] = useLazyGetStockByIdQuery();

  const updateTotal = async (updatedStocks:{ stockId: number, quantity: number }[] ) => {
    let total = 0;

    for(const s of updatedStocks) {
      const {data: stock} = await getStock(s.stockId);
      console.log('[Total calculated] stockId, price ,qty', s.stockId, stock?.priceA, s.quantity);
      if(owner === "Nandos") {
        continue;
      }
      if(stock?.virtual) {
        let autoTotal = 0;
        for (const a of stock.autopulls) {
          autoTotal = autoTotal+ +a.price!;
        }
        total = total + autoTotal*s.quantity;
      } else {
        total = total + +stock!.priceA * s.quantity
      }
    }
    console.log('[Total calculated]', total);
    setTotalCost(total);
  }
  const addStock = async (stockId: number, quantity: number) => {
    const updated = [...selectedStocks.filter(s => s.stockId!== stockId)];
    updated.push({stockId, quantity});
    setSelectedStocks(updated);
    updateTotal(updated);

  }
  const removeStock = (id: number) => {
    const updated = selectedStocks.filter(s => s.stockId !== id)
    setSelectedStocks(updated);
    updateTotal(updated);
  }

  const onSubmit = (job: Partial<IJob>) => {
    setJob(job);
    if (job.poNumber?.trim() === "") {
      onConfirm(job);
    } else {
      onOpen();
    }
  }

  const [totalCost, setTotalCost] = useState(0);

  const onConfirm = async (updates?: Partial<IJob>) => {
    if (!!jobId) {
      const {error} = await updateJob({id: jobId, ...updates! ?? job!});
      if (error) {
        toast({
          title: 'Error in updating job.',
          status: 'error',
          duration: 1000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Job updated successfully.',
          status: 'success',
          duration: 1000,
          isClosable: true,
        });
      }
    } else {
      const {error} = await createJob(job!);
      if (error) {
        toast({
          title: 'Error in creating job.',
          status: 'error',
          duration: 1000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Job updated successfully.',
          status: 'success',
          duration: 1000,
          isClosable: true,
        });
      }
    }
    navigate(-1);
  }
  useEffect(() => {
    if (data)
      setSelectedStocks(data.stocks);
  }, [data]);
  const {isOpen: isChatOpen, onClose: onChatClose, onOpen: onChatOpen} = useDisclosure();
  const [chatJobId, setChatJobId] = useState<number>();
  const openChat = (id: number) => {
    setChatJobId(id);
    onChatOpen();
  }
  return <>
    {isLoading ? <Skeleton/> : <>
      <CreateProject
          goBack={() => {
            navigate(-1);
          }}
          totalCost={totalCost}
          job={data}
          onLoadMoreStocks={loadMoreStocks!}
          createJob={onSubmit}
          removeStock={removeStock}
          selectedStocks={selectedStocks}
          stocks={stocks}
          onSearch={debounce}
          addStock={addStock}
          setOwner={setOwner!}
          openChat={openChat}
      />
      <CreateJobConfirmationDialog
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={() => onConfirm()}
      />
      <ChatContainer
          jobId={chatJobId}
          isOpen={isChatOpen}
          onClose={onChatClose}
      />
    </>}
  </>

}
