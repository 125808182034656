import {
  Box,
  Image,
  Grid,
  GridItem,
  Heading,
  Text,
  Stack, Center, InputGroup, InputLeftElement, Input, HStack,
} from "@chakra-ui/react";
import { IStock } from "../../models/stock";
import React, {useState} from "react";
import {FaSearch} from "react-icons/fa";
import {CategoryListPopoverView} from "../views/CategoryListPopoverView";
import {useSelector} from "react-redux";
import {selectStockQuery} from "../../redux/stores/stockSlice";

interface EquipmentCatalogueProps {
  items: IStock[];
  loading: boolean;
  loadMoreItems: () => Promise<void>;
  openDetails: (stock: IStock) => void;
  onSearch: (value: string) => void;
}
export const EquipmentCatalogue: React.FC<EquipmentCatalogueProps> = ({
  items,
  loading,
  loadMoreItems,
  openDetails,
  onSearch,
}) => {
  const queryStock = useSelector(selectStockQuery);
  const [query, setQuery] = useState(queryStock);
  const handleScroll = (element: any) => {
    const bottom =
      Math.abs(
        element.target.scrollHeight -
          (element.target.scrollTop + element.target.clientHeight)
      ) <= 1;
    console.log(bottom);
    if (bottom) {
      loadMoreItems();
    }
  };
  return (
      <>
        <HStack justifyContent={"flex-end"} w={"100%"} gap={5}>
          <InputGroup padding={3}>
            <InputLeftElement
                pointerEvents="none"
                display="flex"
                left="auto"
                top="auto"
                right="auto"
            >
              <FaSearch color="gray.300" />
            </InputLeftElement>
            <Input
                type="text"
                placeholder="Search"
                borderRadius="50px"
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                  if (!!onSearch) {
                    onSearch(e.target.value);
                  }
                }}
            />
          </InputGroup>
          <CategoryListPopoverView />
        </HStack>
        <Box
            onScroll={handleScroll}
            height={"calc(100vh - 180px)"}
            overflow="scroll"
        >
          <Grid
              padding="10px"
              templateColumns="repeat(4, 1fr)"
              autoRows={"auto"}
              gap={4}
          >
            {items.map((item) => (
                <GridItem key={item.id}>
                  <Box
                      height="300px"
                      onClick={() => openDetails(item)}
                      maxW="sm"
                      borderWidth="1px"
                      borderRadius="lg"
                      overflow="hidden"
                  >
                    <Stack direction="column" gap={0}>
                      <Box height="208px"
                           width="100%" backgroundColor="#717171">
                        <Image
                            height="208px"
                            width="100%"
                            src={item.imageUrl.split(',')[0]}
                            alt={item.name}
                            fit="contain"
                            style={{
                              maxHeight: "200px",
                            }}
                        />
                      </Box>

                      <Box height="100px" backgroundColor="#585858">
                        <Box p="3" color="white">
                          <Heading as="h6" size="md" noOfLines={1}>
                            {item.name}
                          </Heading>
                          <Text>{item.category?.heading}</Text>
                          <Text color="#9FFF9F">Stock: {item.qty}</Text>
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                </GridItem>
            ))}
            {loading && <Box>Loading...</Box>}
            {!loading && items.length === 0 && <Center>
                No Products found
            </Center>}
          </Grid>
        </Box>
      </>
  );
};
