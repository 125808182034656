import { Input, Text } from "@chakra-ui/react";

export interface TextFieldProps {
  name: string;
  value?: string;
  placeholder?: string;
  label?: string;
  type?: string;
  error?: string;
  disabled?: boolean;
  touched?: boolean;
  onChange?: (event: React.ChangeEvent<any>) => void;
  onBlur?: () => void;
}

const TextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  const {
    name,
    value,
    label,
    placeholder,
    type,
    error,
    disabled,
    touched,
    onChange,
    onBlur,
  } = props;
  return (
    <div
      style={{
        margin: "0",
        width: "100%",
      }}
    >
      <Text>{label}</Text>
      <Input
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        type={type || "text"}
        disabled={disabled}
        style={{
          border: "1px solid grey",
          borderRadius: 20,
          padding: "20px 10px",
          background: "#fff",
          color: "#000"
        }}
        focusBorderColor="transparent"
      />
      {touched && error && (
        <Text style={{ fontSize: "12px", color: "red" }}>{error}</Text>
      )}
    </div>
  );
};

export default TextField;
