import {createEntityAdapter, createSelector, createSlice} from "@reduxjs/toolkit";
import {IStock} from "../../models/stock";
import {RootState} from "./AppStore";


export const stocksAdapter = createEntityAdapter<IStock>();

const initialState = stocksAdapter.getInitialState({
  offset: 0,
  query: "",
  loading: false,
  categoryId: "",
  owner: "Nandos"
});

export const stockSlice = createSlice({
  name: 'stocks',
  initialState,
  reducers: {
    stocksLoaded: stocksAdapter.setAll,
    stockAdded: stocksAdapter.addOne,
    stockUpdated: stocksAdapter.updateOne,
    stocksLoading: (state, action) => {
      state.loading = action.payload;
    },
    moreStockLoaded: stocksAdapter.addMany,
    stocksQuery: (state, action) => {
      state.query = action.payload;
    },
    stocksCategory: (state, action) => {
      state.categoryId = action.payload;
    },
    stocksUser: (state, action) => {
      state.owner = action.payload;
    },
  },
});
type StockState = ReturnType<typeof stockSlice.getInitialState>
export const {
  selectAll: selectAllStocks,
  selectById: selectStockById,
  selectTotal: selectTotalStocksCount,
} = stocksAdapter.getSelectors<RootState>(state => state.stocks);

export const stocksReducer = stockSlice.reducer;
export const stockState = (state: RootState) => state.stocks;

export const selectStockQuery = createSelector(stockState, (state) => state.query);
export const selectStockCategory = createSelector(stockState, (state) => state.categoryId);

export const selectStockLoading = createSelector(stockState, (state) => state.loading);
export const selectStockOwner = createSelector(stockState, (state) => state.owner);
export const getStockById = (stockId: number) =>
    createSelector(
        (state: RootState) => state,
        state => selectStockById(state, stockId)
    )
export const {
  stocksQuery,
  stocksCategory,
  stocksLoading,
  stocksUser,
  stocksLoaded,
  moreStockLoaded,
  stockUpdated,
  stockAdded,
} = stockSlice.actions;
