import { Box, Flex, Heading } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import { Outlet } from "react-router";
import SidenavItems from "./SideNavItems";

export const AccountsContainer: React.FC<PropsWithChildren> = () => {
  return (
    <Flex height="calc(100% - 100px)">
      <Box width="20%" borderRight="1px solid grey" marginRight="20px">
        <Heading padding={3}>Account</Heading>
        <SidenavItems
          navItems={[
            {
              label: "Account Details",
              to: "/accounts/details",
            },
            {
              label: "Admin Control",
              to: "/accounts/admin",
            },
            // {
            //   label: "Past Jobs",
            //   to: "/accounts/jobs",
            // },
          ]}
        />
      </Box>
      <Box width="80%"><Outlet/></Box>
    </Flex>
  );
};
