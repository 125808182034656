import {
  Box,
  Button,
  Card,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { ICustomCategory } from "../../../models/category";
import React, {useState} from "react";
import { FaCaretDown } from "react-icons/fa";

interface Props {
  selectedCategory?: ICustomCategory;
  onCategorySelect: (category?: ICustomCategory) => void;
  categories: ICustomCategory[];
  loading: boolean;
  loadMoreCategories: () => Promise<void>;
}

export const CategoryListPopover: React.FC<Props> = ({
  selectedCategory,
  onCategorySelect,
  categories,
  loading,
  loadMoreCategories,
}) => {
  const handleScroll = (element: any) => {
    const bottom =
      Math.abs(
        element.target.scrollHeight -
          (element.target.scrollTop + element.target.clientHeight)
      ) <= 1;
    console.log(bottom);
    if (bottom) {
      loadMoreCategories!();
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  return (
    <Box padding="16px">
      <Popover placement={"right-end"} isOpen={isOpen} onOpen={open} onClose={close}>
        <PopoverTrigger >
          <Button width={"100%"} variant="outline" rightIcon={<FaCaretDown />}>
            {selectedCategory?.name ?? "Choose Category"}
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody
            height="300px"
            marginTop="15px"
            onScroll={handleScroll}
            overflow="scroll"
          >
            <Card
              cursor="pointer"
              marginY="5px"
              padding="8px"
              key={-1}
              onClick={() => {
                close();
                onCategorySelect(undefined);
              }}
            >
              All
            </Card>
            {categories.map((v) => {
              return (
                <Card
                  cursor="pointer"
                  marginY="5px"
                  padding="8px"
                  key={v.id}
                  onClick={() => {
                    close();
                    onCategorySelect(v);
                  }}
                >
                  {v.name}
                </Card>
              );
            })}
            {loading && <Box>Loading...</Box>}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
