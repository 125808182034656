import { Button, Card, Center, Flex, Heading, Spacer } from "@chakra-ui/react";
import React from "react";
import TextField from "../../components/TextField";
import { Form, Formik } from "formik";

interface Props {
  signup: (name: string, email: string, password: string) => void;
}
const SignUp: React.FC<Props> = ({ signup }) => {
  return (
    <Center height="100vh" width="100vw" background="#000">
      <Card height="60%" width="60%" background="#808080" color="#fff">
        <Formik
          initialValues={{
            name: "",
            email: "",
            password: "",
          }}
          onSubmit={(values) => {
            signup(values.name, values.email, values.password);
          }}
        >
          {(formikProps) => (
            <Form>
              <Center>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent={"center"}
                  padding="30px"
                  width="50%"
                >
                  <Heading>Sign Up</Heading>
                  <Spacer></Spacer>
                  <TextField
                    label="Name"
                    name="name"
                    value={formikProps.values.name}
                    onChange={formikProps.handleChange}
                  />
                  <TextField
                    label="Email"
                    name="email"
                    value={formikProps.values.email}
                    onChange={formikProps.handleChange}
                  />
                  <TextField
                    label="Password"
                    name="password"
                    value={formikProps.values.password}
                    onChange={formikProps.handleChange}
                  />
                  <Button type="submit">Sign Up</Button>
                </Flex>
              </Center>
            </Form>
          )}
        </Formik>
      </Card>
    </Center>
  );
};

export default SignUp;
