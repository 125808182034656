import {DamageReportEventAction, DamageReportStatus} from "./models/damageReports";
import {IUser, Role} from "./models/user";

export const getStatusColor = (status: DamageReportStatus) => {
  switch (status) {
    case DamageReportStatus.PENDING:
      return "orange"
    case DamageReportStatus.CANCELLED:
      return "red"
    case DamageReportStatus.APPROVED:
      return "green"
    default:
      return "black"
  }
}

export const getEventColor = (status: DamageReportEventAction) => {
  switch (status) {
    case DamageReportEventAction.ACCEPTED:
      return "green"
    case DamageReportEventAction.ARCHIVED:
      return "orange"
    case DamageReportEventAction.CREATED:
      return "yellow"
    case DamageReportEventAction.REJECTED:
      return "red"
    default:
      return "white"
  }
}

export const getRoleName = (role: Role) => {
  switch (role) {
    case Role.SUPER_ADMIN:
      return "Super Admin"
    case Role.ADMIN:
      return "Admin"
    case Role.MANAGER:
      return "Manager"
    default:
      return "User"
  }
}

export const getDisplayName = (user: IUser) => {
  return !!user.name ? user.name : user.email;
}