import React, {useCallback, useEffect, useState} from 'react';
import {
  useDeleteDamageReportsMutation,
  useFetchAllDamageReportsQuery,
  useUpdateDamageReportsMutation
} from "../../redux/stores/apiSlice";
import DamageReportsPage from "../components/DamageReportsPage";
import useAuth from "../../hooks/useAuth";
import {useToast} from "@chakra-ui/react";
import {DamageReportStatus, IDamageReport, IDamageReportEvent} from "../../models/damageReports";
import DamageReportEvents from "../components/DamageReportEvents";

const DamageReportsView = () => {
  const toast = useToast();
  const [showArchived, setShowArchived] = useState<boolean>(false);
  const [showEvents, setShowEvents] = useState<boolean>(false);
  const [events, setEvents] = useState<IDamageReportEvent[]>([]);
  const [filteredDamageReports, setFilteredDamageReports] = useState<IDamageReport[]>([]);
  const {isLoading, data} = useFetchAllDamageReportsQuery(null);
  const [updateDamageReportMutation] = useUpdateDamageReportsMutation();
  const [deleteDamageReportMutation] = useDeleteDamageReportsMutation();
  const auth = useAuth();

  const updateDamageReport = useCallback((id: number, updates: Partial<IDamageReport>) => {
    updateDamageReportMutation({id, ...updates}).then(() => {
      toast({
        title: 'Damage report updated!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    })
  }, [toast, updateDamageReportMutation]);

  const deleteDamageReport = useCallback((id: number) => {
    deleteDamageReportMutation(id).then(() => {
      toast({
        title: 'Damage report deleted!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    })
  }, [toast, deleteDamageReportMutation]);

  useEffect(() => {
    if (!isLoading && data && data.length > 0) {
      if (showArchived) {
        setFilteredDamageReports([
          ...data.filter(r => r.status === DamageReportStatus.ARCHIVED)
        ])
      } else {
        setFilteredDamageReports([
          ...data.filter(r => r.status !== DamageReportStatus.ARCHIVED)
        ])
      }
    }

  }, [showArchived, isLoading, data]);

  return (
      <>
        <DamageReportsPage
            user={auth.user!}
            updateDamageReport={(stockId, update) => {
              updateDamageReport(stockId, update);
            }}
            deleteDamageReport={deleteDamageReport}
            loading={isLoading}
            damageReports={filteredDamageReports}
            showArchived={showArchived}
            toggleArchived={() => {
              setShowArchived((a) => !a)
            }}
            showEvents={(events) => {
              setEvents(events);
              setShowEvents(true);
            }}
        />
        <DamageReportEvents
            isOpen={showEvents}
            onClose={() => setShowEvents(false)}
            events={events!}/>
      </>

  );
};

export default DamageReportsView;