import { useContext } from "react";

import { AuthContext } from "../auth/auth-provider";

const useAuth = () => {
  const authContext = useContext(AuthContext);
  return {
    ...authContext,
  };
};

export default useAuth;
