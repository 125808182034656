import React, { PropsWithChildren, useEffect, useState } from "react";
import useApi from "../hooks/useApi";
import { IUser } from "../models/user";
import { useNavigate } from "react-router";
import {ICompany} from "../models/company";
import {useLazyGetCompanyByIdQuery} from "../redux/stores/apiSlice";

interface IAuthContext {
  tokenLoading: boolean;
  isAuthenticated: boolean;
  user: IUser | undefined;
  company?: ICompany;
  updateUser?: (updates: Partial<IUser>) => Promise<IUser>;
  updatePassword?: (password: string) => Promise<boolean | undefined>;
  login?: (email: string, password: string) => Promise<void>;
  signup?: (name: string, email: string, password: string) => Promise<void>;
  logout?: () => void | undefined;
}

export const AuthContext = React.createContext<IAuthContext>({
  tokenLoading: true,
  isAuthenticated: false,
  user: undefined,
  company: undefined,
  login: undefined,
  signup: undefined,
  logout: undefined,
  updateUser: undefined,
  updatePassword: undefined,
});

const AuthProvider: React.FC<PropsWithChildren> = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>();
  const [company, setCompany] = useState<ICompany>();
  const [loading, setLoading] = useState<boolean>(true);
  const { get, post, put, setToken, deleteToken } = useApi();
  const navigate = useNavigate();
  const [getCompanyById] = useLazyGetCompanyByIdQuery();
  const bootstrapAsync = async () => {
    console.log("Checking token validity");
    if (isAuthenticated) return;
    if (!isAuthenticated && !loading) return;
    // setLoading(true);
    try {
      const user = await get("/auth/me");
      setUser(user);
      setIsAuthenticated(true);
      setLoading(false);
    } catch (e) {
      console.log("Checking token validity failed");
      setLoading(false);
    }
  };
  useEffect(() => {
    bootstrapAsync();
  }, []);
  useEffect(() => {
    if(user && user.companyId) {
      fetchCompany();
    }
  }, [user]);

  const login = async (email: string, password: string) => {
    try {
      const res = await post("/auth/login", { email, password });
      await setToken(res.token);
      setUser(res.user);
      if (!res.updatePassword) {
        setIsAuthenticated(true);
        navigate("/");
      } else {
        navigate("/reset-password");
      }
    } catch (err) {
      await deleteToken();
      setIsAuthenticated(false);
      setLoading(false);
    }
  };

  const fetchCompany = async () => {
    try {
      const {data} = await getCompanyById(user!.companyId);
      setCompany(data);
    } catch (err) {
      console.log(err);
    }
  };
  const signup = async (name: string, email: string, password: string) => {
    try {
      const res = await post("/auth/signup", { name, email, password });
      await setToken(res.token);
      setIsAuthenticated(true);
      navigate("/dashboard");
    } catch (err) {
      await deleteToken();
      setIsAuthenticated(false);
      setLoading(false);
    }
  };
  const logout = () => {
    setIsAuthenticated(false);
    deleteToken();
    navigate("/");
  };

  const updateUser = async (updates: Partial<IUser>) => {
    try {
      const res = await put("/auth/me", updates);
      setUser(res);
      return res;
    } catch (e) {
      console.log(e);
    }
  };
  const updatePassword = async (
    password: string
  ): Promise<boolean | undefined> => {
    try {
      const res = await put("/auth/password", { password });
      setIsAuthenticated(true);
      navigate("/");
      return res.success;
    } catch (e) {
      console.log(e);
    }
  };
  console.log("Render AuthProvider", isAuthenticated, loading);
  return (
    <AuthContext.Provider
      value={{
        tokenLoading: loading,
        login,
        logout,
        signup,
        isAuthenticated,
        user,
        updateUser,
        updatePassword,
        company
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
