import React from "react";
import {IJob, getStatusColor} from "../../models/job";
import {Box, Button, Card, Center, Flex, HStack, IconButton, Skeleton, VStack} from "@chakra-ui/react";
import {AddIcon, ChatIcon} from "@chakra-ui/icons";


interface Props {
  jobs: IJob[];
  loading: boolean;
  onAddNew: () => void;
  openChat: (id: number) => void;
  onEdit: (id: number) => void;
}

export const ProjectList: React.FC<Props> = ({jobs, openChat, onEdit, loading, onAddNew}) => {

  return <VStack gap={10}>
    {
      loading && [0,1,2].map(e => {
        return <Skeleton h={150} w={'100%'}/>
        })
    }
    {!loading && jobs && jobs.length === 0 && <VStack justifyContent={"center"} h={'calc(100vh - 100px)'} gap={10}>
        <Box>No Jobs Yet</Box>
        <Button onClick={onAddNew} leftIcon={<AddIcon/>} colorScheme='teal'
                variant='solid'>
            New Job
        </Button>
    </VStack>}
    {
      jobs?.map(job => {
        return <Card key={job.id} pl={10} overflow={"hidden"} mr={10} color="#fff" bg='#808080' width="100%" height={150}
                     display={"flex"} justify={'center'} alignItems={"center"}>
          <HStack w='100%' h={'100%'} justifyContent={"space-between"} alignItems={"stretch"}>
            <VStack justifyContent={"center"} alignItems={"flex-start"} h={'100%'}>
              <Box>Job name: {job.name}</Box>
              {job.setupDate && <Box>Date: {job.setupDate?.toString().slice(0, 16)}</Box>}
              <Box>Location: {job.w3wLocation}</Box>
            </VStack>
            <VStack justifyContent={"center"} alignItems={"flex-start"} h={'100%'}>
              <Box>PO Number: {job.poNumber}</Box>
              <Box>Job Total: £{job.total} + VAT</Box>
            </VStack>
            <Box w={'80px'} h="100%" bg={getStatusColor(job.status)}></Box>
            <Button w={'150px'} size={"sm"} position={"absolute"} right={2} top={2}
                    onClick={() => onEdit(job.id)}>Edit</Button>
            <IconButton aria-label={'Chat'} icon={<ChatIcon/>} size={"sm"} position={"absolute"} right={2} bottom={2}
                        onClick={() => openChat(job.id)}/>
          </HStack>

        </Card>
      })
    }
  </VStack>
}
